<template>
  <section>
    <v-sheet class="mx-auto py-3 transparent">
      <v-slide-group :show-arrows="$vuetify.breakpoint.smAndUp">
        <v-slide-item v-for="category in categories" :key="category.id">
          <v-btn
            class="mx-2 grey--text slide-group-menu-btn text-body-2"
            :input-value="category.id === currentCategoryId"
            large
            active-class="no-active white--text"
            depressed
            rounded
            @click="setCurrentCategory(category.id)"
          >
            {{ category.name }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-col cols="12" class="pt-0">
      <v-divider class="mt-0" />
    </v-col>
  </section>
</template>

<script>
import { slideCategoriesCtrl, settingCtrl } from "@/controllers";
export default {
  name: "SlideCategories",
  provide() {
    return {
      heading: { align: "center" },
    };
  },
  data() {
    return {
      categories: [],
      currentCategoryId: null,
    };
  },
  async mounted() {
    const { show_combined_aggregated_categories } =
      await settingCtrl.getGlobalSetting();
    const sendExtraOnlyMain = !show_combined_aggregated_categories;
    slideCategoriesCtrl.getCategories(sendExtraOnlyMain).then((categories) => {
      this.categories = categories;
      this.setCurrentCategory(this.categories[0].id);
    });
  },

  methods: {
    setCurrentCategory(categoryId) {
      this.currentCategoryId = categoryId;
      slideCategoriesCtrl
        .getCategory(this.currentCategoryId)
        .then((category) => {
          this.$emit("updateListProduct", category);
        });
    },
  },
};
</script>
<style lang="sass" scoped>
::v-deep .no-active
  background-color: transparent !important
  opacity: 1 !important
  border: 0.17rem solid var(--v-primary-base)
  > span
    color: var(--v-primary-base)
</style>
